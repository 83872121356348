export default function StyledDiv({ children, backgroundColor, ...rest }) {
  return (
    <div style={mapStyling({ backgroundColor })} {...rest}>
      {children}
    </div>
  );
}

function mapStyling({ backgroundColor }) {
  return {
    backgroundColor: backgroundColor || '#fff',
  };
}
