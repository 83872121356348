import styles from './styles.module.sass';
import { ItemProps } from 'generic/Timeline';
import { buildCssVar } from 'utils/style-override';

const defaultProps = {
  title: '',
  content: '',
  box_background_color: '',
};

export function TimelineCardMobile({ title, content, box_background_color }: ItemProps) {
  return (
    <div className={`root ${styles.timelineItem}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', box_background_color, 'var(--text-color-sea)')}
        }
      `}</style>
      <div className={styles.content}>
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
}

TimelineCardMobile.defaultProps = defaultProps;
