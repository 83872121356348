import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTextFontOverrides } from 'utils/font-override';
import { useFunnelData } from 'utils/funnel-data-context';
import { buildFunnelPath } from 'utils/url';
import styles from './CtaButton.module.css';
import { buildCssVar } from 'utils/style-override';
import { useCallback, useState } from 'react';
import Spinner from './Spinner';

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  buttonColor: null,
  buttonHoverColor: null,
  buttonTextColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  textFont: null,
};

function CtaButton({
  buttonText,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  textFont,
}) {
  const { funnelId, salesPath } = useFunnelData();
  const [shouldShowActivityIndicator, setShouldShowActivityIndicator] = useState(false);

  const showActivityIndicator = useCallback(() => {
    setShouldShowActivityIndicator(true);
  }, []);

  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--button-color', buttonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {shouldShowActivityIndicator && <Spinner />}
      <Link href={buildFunnelPath(salesPath, funnelId)} legacyBehavior>
        <a className={styles.button} onClick={showActivityIndicator}>
          <p>{buttonText}</p>
        </a>
      </Link>
    </div>
  );
}

CtaButton.propTypes = propTypes;
CtaButton.defaultProps = defaultProps;

export default CtaButton;
