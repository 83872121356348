import * as yup from 'yup';

import StyledDiv from 'generic/StyledDiv';

import { buildCssVar } from 'utils/style-override';

import { TimelineCardMobile } from './components/Mobile';
import { TimelineCardDesktop } from './components/Desktop';

import styles from './styles.module.sass';
import dynamic from 'next/dynamic';

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false,
});

const defaultProps = {
  timelines: [],
};

export const itemValidation = yup.object().shape({
  title: yup.string().required(),
  content: yup.string().required(),
  box_background_color: yup.string().nullable(),
  font_size: yup.string().nullable(),
});

export const timelineItemValidation = yup.object().shape({
  id: yup.string().required(),
  item: yup.array().of(itemValidation),
});

const timelineValidation = yup.object().shape({
  id: yup.string().required(),
  title: yup.string().required(),
  items: yup.array().of(timelineItemValidation),
  background_color: yup.string().nullable(),
  font_size: yup.string().nullable(),
});

export type ItemProps = yup.InferType<typeof itemValidation>;
export type TimelineItemProps = yup.InferType<typeof timelineItemValidation>;
export type TimelineProps = yup.InferType<typeof timelineValidation>;

export default function Timeline(timeline: TimelineProps) {
  return (
    <StyledDiv className={`root ${styles.timeline}`} backgroundColor={timeline.background_color}>
      <style jsx>{`
        .root {
          ${buildCssVar(
            '--background-color',
            timeline?.background_color,
            'var(--text-color-grey-dark)'
          )}
        }
      `}</style>
      <div className={styles.container}>
        <div className={styles.column}>
          <h2 className={styles.title}>{timeline.title}</h2>

          {timeline.items?.map(item => (
            <>
              <MediaQuery maxWidth={980}>
                <TimelineCardMobile key={item.id} {...item.item} />
              </MediaQuery>
              <MediaQuery minWidth={981}>
                <TimelineCardDesktop key={item.id} {...item.item} />
              </MediaQuery>
            </>
          ))}
        </div>
      </div>
    </StyledDiv>
  );
}

Timeline.defaultProps = defaultProps;
