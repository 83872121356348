import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import LazyAutoplayVideo from './LazyAutoplayVideo';
import Image from './Image';
import styles from './ContentModule.module.css';
import { useRouter } from 'next/router';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  sectionId: PropTypes.string,
  heading: PropTypes.string,
  placeholderImage: PropTypes.object,
  video: PropTypes.object,
  image: PropTypes.object,
  mediaAlt: PropTypes.string,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.object,
  textFont: PropTypes.object,
  fontSpacing: PropTypes.number
};

const defaultProps = {
  sectionId: null,
  heading: null,
  placeholderImage: null,
  video: null,
  image: null,
  mediaAlt: null,
  text: null,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
  fontSpacing: null,
};

export default function ContentModule({
  sectionId,
  heading,
  image,
  text,
  headingColor,
  textColor,
  backgroundColor,
  headingFont,
  textFont,
  mediaAlt,
  video,
  placeholderImage,
  fontSpacing,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const router = useRouter();
  return (
    <div id={sectionId} className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--letter-spacing', `${fontSpacing ?? 0}px`)}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--text-color-dark)')}
            ${headingFontOverrides ?? ''}
            ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.wrapper}>
        <h1 className={styles.heading}>{heading}</h1>
        {image && (
          <div className={styles.image_wrapper}>
            <Image src={image} alt={mediaAlt} />
          </div>
        )}
        {video && placeholderImage && (
          <div className={styles.video_wrapper}>
            <LazyAutoplayVideo alt={mediaAlt} placeholderImage={placeholderImage} video={video} />
          </div>
        )}
        <div className={styles.text}>
          {text && <MarkdownText text={text} queryParams={router.query} />}
        </div>
      </div>
    </div>
  );
}

ContentModule.propTypes = propTypes;
ContentModule.defaultProps = defaultProps;
